<template>
  <div class="row">
    <div class="col-lg-12">
      <el-alert :closable="false" class="mb-3" title="💡 Info" type="success">
        <p><b>Minibot</b> setting is a place where you can set what message to reply to your customer. By default, we have 3 predefined thread.</p>
        <ol>
          <li>Thread <b>Default</b>. This is the first message when your customer send a new message to your business before we created a ticket for your customer</li>
          <li>Thread <b>Ticket</b>. This message will be send after your customer respond to thread <b>Default</b>, we will create a new ticket for your customer and assign the ticket to your agent. The ticket will have unique ticket number per workspace. You can hide or show the ticket number with a variable that we define below.</li>
          <li>Thread <b>Closing</b>. This message will be send when you mark a conversation as <b>Resolved</b>. You can use this message to inform your customer know that their ticket has been marked as resolved.</li>
        </ol>
        This is our predefined variable you can used:<br/>
        - Use <code style="font-size: 12px" v-html="'{{name}}'"></code> to be replace with
        <b>customer name</b><br>
        - Use <code style="font-size: 12px" v-html="'{{ticket_no}}'"></code> to be replace with
        <b>ticket number</b>
      </el-alert>
      <el-form label-width="130px" label-position="left">
        <el-form-item v-for="(q, index) in questions" :key="index">
          <span class="font-weight-bold" slot="label">Thread {{q.topic}}</span>
          <el-input rows="5" type="textarea" v-model="questions[index].text[0]"></el-input>
          <template v-if="index == 0 || index == 2">
            <el-switch active-text="Enable Button" v-model="questions[index].enabled_button" :value="['list', 'button'].includes(questions[index].action_type)"></el-switch>
            <div class="row">
              <div class="col-2">
                <el-select @change="handlerChangeButtonType($event, index)" size="small" v-if="questions[index].enabled_button" v-model="questions[index].action_type">
                  <el-option label="Button" value="button">Button</el-option>
                  <el-option label="List" value="list">List</el-option>
                </el-select>
              </div>
              <div class="col-8" v-if="questions[index].enabled_button">
                <span v-if="questions[index].action_type == 'button'">
                  <div class="inline inline-flex align-middle"
                    v-for="(item, i) in temp_buttons_form[index]" :key="i">
                    <el-input size="small" v-model="item.reply.title"
                      class="mb-2 w-50 mr-2"
                      maxlength="20"
                      show-word-limit/>
                    <span v-if="item.length > 1">
                      <a @click="handlerRemoveQuickReply(i, index)" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1.5em" icon="times-circle"/></a>
                    </span>
                  </div>
                  <el-button
                    @click="handlerAddButton(index)"
                    :disabled="temp_buttons_form[index].length === 3"
                    type="info"
                    size="small"
                    class="mt-2"><font-awesome icon="plus"/>&nbsp; Add Another Button
                  </el-button>
                </span>
                <span v-if="questions[index].action_type == 'list'">
                  <b-form-group>
                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="9"
                      label="Text Button">
                      <el-input v-model="temp_list_form[index].button" size="small" placeholder="Input button text" type="text"></el-input>
                    </b-form-group>
                    <span v-for="(list, ilist) in temp_list_form[index].sections[0].rows" :key="ilist">
                      <el-divider>List Content - {{ (ilist + 1) }}</el-divider>
                      <b-row>
                        <b-col :md="temp_list_form[index].sections[0].rows.length > 1 ? 5 : 6">
                          <el-input maxlength="24" show-word-limit v-model="list.title" size="small" placeholder="Input list title" type="text"></el-input>
                        </b-col>
                        <b-col md="6">
                          <el-input maxlength="72" show-word-limit v-model="list.description" size="small" placeholder="Input list description" type="textarea"></el-input>
                        </b-col>
                        <b-col md="1" v-if="temp_list_form[index].sections[0].rows.length > 1">
                          <span>
                            <a @click="handlerRemoveList(ilist, index)" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1.5em" icon="times-circle"/></a>
                          </span>
                        </b-col>
                      </b-row>
                    </span>
                    <el-button
                      @click="handlerAddList(index)"
                      :disabled="temp_list_form[index].sections[0].rows.length > 9"
                      type="info"
                      size="small"
                      class="mt-2"><font-awesome icon="plus"/>&nbsp; Add Another List
                    </el-button>
                  </b-form-group>
                </span>
              </div>
            </div>
          </template>
        </el-form-item>
      </el-form>
      <div align="right">
        <el-button @click="updateMinibot" :loading="loading.update_bot" size="small"
          class="btn-primary" align="right">Update</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { upperFirst } from 'lodash';
import workspaces from '../../../api/workspaces';
import popupErrorMessages from '../../../library/popup-error-messages';

// const crypto = require('crypto');

export default {
  name: 'Minibot',
  // metaInfo: {
  //   title: 'Minibot',
  // },
  props: {
    workspace_id: {
      type: String,
    },
  },
  data() {
    return {
      loading: {
        setting: true,
        update_bot: false,
        update_work_hour: false,
        auto_reminder: false,
      },
      loaderStack: 0,
      loader: null,
      questions: [],
      work_hour: {
        enabled: false,
        config: [],
        message: '',
      },
      active_tab: 'minibot',
      search: '',
      temp_buttons_form: [],
      temp_list_form: [],
    };
  },
  methods: {
    upperCaseFirst(string) {
      return upperFirst(string);
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async loadData() {
      this.$parent.showLoader();
      const response = await workspaces.bot_questions({ id: this.workspace_id });
      this.$parent.hideLoader();
      this.$parent.loading.setting = false;
      await popupErrorMessages(response);
      // console.log(response.data);
      this.questions = response.data.questions.map((v, i) => {
        v.enabled_button = ['list', 'button'].includes(v.action_type);
        if (v.enabled_button) {
          this.temp_buttons_form[i] = v.buttons;
          this.temp_list_form[i] = v.list;
        }
        return v;
      });
    },
    async updateMinibot() {
      this.$parent.showLoader();
      if (!this.questions[0].enabled_button) {
        delete this.questions[0].action_type;
        delete this.questions[0].buttons;
        delete this.questions[0].list;
      }
      if (!this.questions[2].enabled_button) {
        delete this.questions[2].action_type;
        delete this.questions[2].buttons;
        delete this.questions[2].list;
      }
      const response = await workspaces.update_bot_questions({ id: this.workspace_id, data: { questions: this.questions } });
      this.$parent.hideLoader();
      this.$parent.loading.update_bot = false;
      await popupErrorMessages(response);
      this.$message({
        type: 'success',
        message: this.$t('settings.success.edit'),
      });
      this.loadMinibot();
    },
    /* eslint-disable no-restricted-globals */
    handlerChangeButtonType(value, index) {
      if (value === 'button') {
        this.questions[index].buttons = [{
          type: 'reply',
          reply: {
            id: self.crypto.randomUUID(),
            title: '',
          },
        }];
        // this.temp_buttons_form[index] = this.questions[index].buttons;
        this.$set(this.temp_buttons_form, index, this.questions[index].buttons);
      } else if (value === 'list') {
        this.questions[index].list = {
          button: '',
          sections: [{
            title: '',
            rows: [{
              id: self.crypto.randomUUID(),
              title: '',
              description: '',
            }],
          }],
        };
        // this.temp_list_form[index] = this.questions[index].list;
        this.$set(this.temp_list_form, index, this.questions[index].list);
      }
    },
    handlerRemoveQuickReply(index) {
      if (this.questions[index].buttons.length > 1) {
        this.questions[index].buttons.splice(index, 1);
      }
    },
    handlerAddButton(index) {
      const current_questions = this.questions[index];
      current_questions.buttons.push({
        type: 'reply',
        reply: {
          id: self.crypto.randomUUID(),
          title: '',
        },
      });
      this.$set(this.questions, index, current_questions);
    },
    handlerAddList(index) {
      const current_questions = this.questions[index];
      console.log(current_questions);
      current_questions.list.sections[0].rows.push({
        id: self.crypto.randomUUID(),
        title: '',
        description: '',
      });
      this.$set(this.questions, index, current_questions);
    },
    handlerRemoveList(index, i) {
      if (this.questions[i].list.sections[0].rows.length > 1) {
        this.questions[i].list.sections[0].rows.splice(index, 1);
      }
    },
  },
};
</script>
