<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-12">
        <ul class="nav nav-tabs mt-3" ref="tabStatusConvo" id="tabStatusConvo" role="tablist"
          @click="tabSettingClicked">
          <li class="nav-item">
            <a class="nav-link active" ref="minibot-tab" id="minibot-tab" data-toggle="tab" href="#minibot" role="tab" aria-controls="minibot" aria-selected="true">
              <div class="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                <p id="minibot-title">Minibot</p>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" ref="work-hour-tab" id="work-hour-tab" data-toggle="tab" href="#work-hour" role="tab" aria-controls="work-hour" aria-selected="false">
              <div class="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                <p id="work-hour-title">Work Hour</p>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" ref="auto-reminder-tab" id="auto-reminder-tab" data-toggle="tab" href="#auto-reminder" role="tab" aria-controls="auto-reminder" aria-selected="false">
              <div class="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                <p id="auto-reminder-title">Auto Reminder</p>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" ref="other-tab" id="other-tab" data-toggle="tab" href="#other" role="tab" aria-controls="other" aria-selected="false">
              <div class="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                <p id="other-title">Other</p>
              </div>
            </a>
          </li>
        </ul>
        <div class="tab-content mt-3" v-loading="loading.setting" style="min-height: 400px; height: auto;">
          <div class="tab-pane fade show active" ref="minibot-content" id="minibot" role="tabpanel" aria-labelledby="minibot">
            <minibot
              ref="minibot"
              :workspace_id="activeWorkspace._id"/>
          </div>
          <div class="tab-pane fade" ref="work-hour-content" id="work-hour" role="tabpanel" aria-labelledby="work-hour">
            <work-hour
              ref="work-hour"
              :workspace_id="activeWorkspace._id"/>
          </div>
          <div class="tab-pane fade" ref="auto-reminder-content" id="auto-reminder" role="tabpanel" aria-labelledby="auto-reminder">
            <auto-reminder
              ref="auto-reminder"
              :workspace_id="activeWorkspace._id"/>
          </div>
          <div class="tab-pane fade" ref="other-content" id="other" role="tabpanel" aria-labelledby="other">
            <el-empty description="Coming soon"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { upperFirst, isEmpty, cloneDeep } from 'lodash';
import popupErrorMessages from '@/library/popup-error-messages';
import workspaces from '@/api/workspaces';
import AutoReminder from '@/ui/components/setting/AutoReminder.vue';
import Minibot from '@/ui/components/setting/Minibot.vue';
import WorkHour from '@/ui/components/setting/WorkHour.vue';

export default {
  components: { AutoReminder, Minibot, WorkHour },
  name: 'Settings',
  metaInfo() {
    return {
      title: this.tab_title[this.active_tab] || 'Settings',
    };
  },
  data() {
    return {
      loading: {
        setting: true,
        update_bot: false,
        update_work_hour: false,
        auto_reminder: false,
      },
      loaderStack: 0,
      loader: null,
      questions: [],
      work_hour: {
        enabled: false,
        config: [],
        message: '',
      },
      active_tab: 'minibot',
      search: '',
      tab_title: {
        minibot: 'Settings - Minibot',
        'work-hour': 'Settings - Work Hour',
        'auto-reminder': 'Settings - Auto Reminder',
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    const temp = [];
    /* eslint-disable no-plusplus */
    for (let i = 0; i < 7; i++) {
      temp.push({
        day: i,
        day_name: moment().weekday(i).format('dddd'),
        from: '08:00',
        until: '17:00',
        model: [moment('08:00', 'hh:mm'), moment('17:00', 'hh:mm')],
        enabled: false,
      });
    }
    this.work_hour.config = temp;
    this.$refs.minibot.loadData();
  },
  methods: {
    upperCaseFirst(string) {
      return upperFirst(string);
    },
    tabSettingClicked(e) {
      const id = e.target.id.replace(/-title|-tab/ig, '');
      if (['minibot', 'work-hour', 'auto-reminder'].includes(id)) {
        this.$refs[id].loadData();
      }
      this.active_tab = id;
    },
    provider(string) {
      let str = '';
      switch (string) {
        case 'makna':
          str = 'Makna';
          break;
        case 'damcorp':
          str = 'Damcorp';
          break;
        case 'client_premise':
          str = 'On premise';
          break;
        default:
          break;
      }
      return str;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async loadWorkHour() {
      this.loading.setting = true;
      const response = await workspaces.work_hour({ id: this.activeWorkspace._id });
      this.hideLoader();
      this.loading.setting = false;
      await popupErrorMessages(response);
      if (!isEmpty(response.data.workhour?.config)) {
        /* eslint-disable no-param-reassign */
        this.work_hour.config = response.data.workhour?.config.map((v, index) => {
          v.day_name = moment().weekday(index).format('dddd');
          v.model = [moment(v.from, 'hh:mm'), moment(v.until, 'hh:mm')];
          return v;
        });
        this.work_hour.enabled = response.data.workhour.enabled;
        this.work_hour.message = response.data.workhour.message;
      }
    },
    async updateWorkHour() {
      this.loading.update_work_hour = true;
      let data_update = cloneDeep(this.work_hour.config);
      data_update = data_update.map((v) => {
        v.from = moment(v.model[0]).format('HH:mm');
        v.until = moment(v.model[1]).format('HH:mm');
        delete v.day_name;
        delete v.model;
        return v;
      });
      const response = await workspaces.update_work_hour({
        id: this.activeWorkspace._id,
        data: {
          /* eslint-disable no-param-reassign */
          workhour: {
            enabled: this.work_hour.enabled,
            config: data_update,
            message: this.work_hour.message,
          },
        },
      });
      this.hideLoader();
      this.loading.update_work_hour = false;
      await popupErrorMessages(response);
      this.$message({
        type: 'success',
        message: this.$t('settings.success.edit'),
      });
      this.loadWorkHour();
    },
  },
};
</script>
<style>
.el-switch__label.is-active {
  color: #10b759 !important;
}
.el-date-editor .el-range-separator {
  width: 15%;
}
.el-switch__label {
  color: #BDC3C7;
}
</style>
